var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { sm: "6", lg: "3" } },
        [
          _c("CWidgetDropdown", {
            attrs: {
              color: "gradient-primary",
              header: "9.823",
              text: "Members online"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c(
                      "CDropdown",
                      {
                        staticClass: "float-right",
                        attrs: {
                          color: "transparent p-0",
                          placement: "bottom-end"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "toggler-content",
                            fn: function() {
                              return [
                                _c("CIcon", { attrs: { name: "cil-settings" } })
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("CDropdownItem", [_vm._v("Action")]),
                        _c("CDropdownItem", [_vm._v("Another action")]),
                        _c("CDropdownItem", [_vm._v("Something else here...")]),
                        _c("CDropdownItem", { attrs: { disabled: "" } }, [
                          _vm._v("Disabled action")
                        ])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "footer",
                fn: function() {
                  return [
                    _c("CChartLineSimple", {
                      staticClass: "px-3",
                      staticStyle: { height: "70px" },
                      attrs: {
                        pointed: "",
                        "data-points": [65, 59, 84, 84, 51, 55, 40],
                        "point-hover-background-color": "primary",
                        label: "Members",
                        labels: "months"
                      }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { sm: "6", lg: "3" } },
        [
          _c("CWidgetDropdown", {
            attrs: {
              color: "gradient-info",
              header: "9.823",
              text: "Members online"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c(
                      "CDropdown",
                      {
                        staticClass: "float-right",
                        attrs: {
                          color: "transparent p-0",
                          placement: "bottom-end",
                          caret: false
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "toggler-content",
                            fn: function() {
                              return [
                                _c("CIcon", {
                                  attrs: { name: "cil-location-pin" }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("CDropdownItem", [_vm._v("Action")]),
                        _c("CDropdownItem", [_vm._v("Another action")]),
                        _c("CDropdownItem", [_vm._v("Something else here...")]),
                        _c("CDropdownItem", { attrs: { disabled: "" } }, [
                          _vm._v("Disabled action")
                        ])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "footer",
                fn: function() {
                  return [
                    _c("CChartLineSimple", {
                      staticClass: "px-3",
                      staticStyle: { height: "70px" },
                      attrs: {
                        pointed: "",
                        "data-points": [1, 18, 9, 17, 34, 22, 11],
                        "point-hover-background-color": "info",
                        options: { elements: { line: { tension: 0.00001 } } },
                        label: "Members",
                        labels: "months"
                      }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { sm: "6", lg: "3" } },
        [
          _c("CWidgetDropdown", {
            attrs: {
              color: "gradient-warning",
              header: "9.823",
              text: "Members online"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c(
                      "CDropdown",
                      {
                        staticClass: "float-right",
                        attrs: {
                          color: "transparent p-0",
                          placement: "bottom-end"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "toggler-content",
                            fn: function() {
                              return [
                                _c("CIcon", { attrs: { name: "cil-settings" } })
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("CDropdownItem", [_vm._v("Action")]),
                        _c("CDropdownItem", [_vm._v("Another action")]),
                        _c("CDropdownItem", [_vm._v("Something else here...")]),
                        _c("CDropdownItem", { attrs: { disabled: "" } }, [
                          _vm._v("Disabled action")
                        ])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "footer",
                fn: function() {
                  return [
                    _c("CChartLineSimple", {
                      staticStyle: { height: "70px" },
                      attrs: {
                        "background-color": "rgba(255,255,255,.2)",
                        "data-points": [78, 81, 80, 45, 34, 12, 40],
                        options: { elements: { line: { borderWidth: 2.5 } } },
                        "point-hover-background-color": "warning",
                        label: "Members",
                        labels: "months"
                      }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { sm: "6", lg: "3" } },
        [
          _c("CWidgetDropdown", {
            attrs: {
              color: "gradient-danger",
              header: "9.823",
              text: "Members online"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c(
                      "CDropdown",
                      {
                        staticClass: "float-right",
                        attrs: {
                          color: "transparent p-0",
                          placement: "bottom-end"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "toggler-content",
                            fn: function() {
                              return [
                                _c("CIcon", { attrs: { name: "cil-settings" } })
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("CDropdownItem", [_vm._v("Action")]),
                        _c("CDropdownItem", [_vm._v("Another action")]),
                        _c("CDropdownItem", [_vm._v("Something else here...")]),
                        _c("CDropdownItem", { attrs: { disabled: "" } }, [
                          _vm._v("Disabled action")
                        ])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "footer",
                fn: function() {
                  return [
                    _c("CChartBarSimple", {
                      staticStyle: { height: "70px" },
                      attrs: {
                        "background-color": "rgb(250, 152, 152)",
                        label: "Members",
                        labels: "months"
                      }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
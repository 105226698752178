var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CChartPolarArea", {
    attrs: {
      datasets: _vm.defaultDatasets,
      options: _vm.defaultOptions,
      labels: [
        "Eating",
        "Drinking",
        "Sleeping",
        "Designing",
        "Coding",
        "Cycling",
        "Running"
      ]
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      !_vm.noCharts
        ? [
            _c(
              "CCol",
              { attrs: { md: "3", sm: "6" } },
              [
                _c(
                  "CWidgetBrand",
                  {
                    attrs: {
                      color: "facebook",
                      "right-header": "89k",
                      "right-footer": "friends",
                      "left-header": "459",
                      "left-footer": "feeds"
                    }
                  },
                  [
                    _c("CIcon", {
                      staticClass: "my-4",
                      attrs: { name: "cib-facebook", height: "52" }
                    }),
                    _c("CChartLineSimple", {
                      staticClass: "c-chart-brand",
                      attrs: {
                        "background-color": "rgba(255,255,255,.1)",
                        "data-points": [65, 59, 84, 84, 51, 55, 40],
                        label: "Friends",
                        labels: "months"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "CCol",
              { attrs: { md: "3", sm: "6" } },
              [
                _c(
                  "CWidgetBrand",
                  {
                    attrs: {
                      color: "twitter",
                      "right-header": "973k",
                      "right-footer": "followers",
                      "left-header": "1.792",
                      "left-footer": "tweets"
                    }
                  },
                  [
                    _c("CIcon", {
                      staticClass: "my-4",
                      attrs: { name: "cib-twitter", height: "52" }
                    }),
                    _c("CChartLineSimple", {
                      staticClass: "c-chart-brand",
                      attrs: {
                        "background-color": "rgba(255,255,255,.1)",
                        "data-points": [1, 13, 9, 17, 34, 41, 38],
                        label: "Followers",
                        labels: "months"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "CCol",
              { attrs: { md: "3", sm: "6" } },
              [
                _c(
                  "CWidgetBrand",
                  {
                    attrs: {
                      color: "linkedin",
                      "right-header": "500+",
                      "right-footer": "contracts",
                      "left-header": "292",
                      "left-footer": "feeds"
                    }
                  },
                  [
                    _c("CIcon", {
                      staticClass: "my-4",
                      attrs: { name: "cib-linkedin", height: "52" }
                    }),
                    _c("CChartLineSimple", {
                      staticClass: "c-chart-brand",
                      attrs: {
                        "background-color": "rgba(255,255,255,.1)",
                        "data-points": [78, 81, 80, 45, 34, 12, 40],
                        label: "Contracts",
                        labels: "months"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "CCol",
              { attrs: { md: "3", sm: "6" } },
              [
                _c(
                  "CWidgetBrand",
                  {
                    attrs: {
                      "right-header": "12",
                      "right-footer": "events",
                      "left-header": "4",
                      "left-footer": "meetings",
                      color: "gradient-warning"
                    }
                  },
                  [
                    _c("CIcon", {
                      staticClass: "my-4",
                      attrs: { name: "cil-calendar", height: "52" }
                    }),
                    _c("CChartLineSimple", {
                      staticClass: "c-chart-brand",
                      attrs: {
                        "background-color": "rgba(255,255,255,.1)",
                        "data-points": [35, 23, 56, 22, 97, 23, 64],
                        label: "Followers",
                        labels: "months"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        : [
            _c(
              "CCol",
              { attrs: { md: "3", sm: "6" } },
              [
                _c(
                  "CWidgetBrand",
                  {
                    attrs: {
                      color: "facebook",
                      "right-header": "89k",
                      "right-footer": "friends",
                      "left-header": "459",
                      "left-footer": "feeds"
                    }
                  },
                  [
                    _c("CIcon", {
                      staticClass: "my-4",
                      attrs: { name: "cib-facebook", height: "56" }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "CCol",
              { attrs: { md: "3", sm: "6" } },
              [
                _c(
                  "CWidgetBrand",
                  {
                    attrs: {
                      color: "twitter",
                      "right-header": "973k",
                      "right-footer": "followers",
                      "left-header": "1.792",
                      "left-footer": "tweets"
                    }
                  },
                  [
                    _c("CIcon", {
                      staticClass: "my-4",
                      attrs: { name: "cib-twitter", height: "56" }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "CCol",
              { attrs: { md: "3", sm: "6" } },
              [
                _c(
                  "CWidgetBrand",
                  {
                    attrs: {
                      color: "linkedin",
                      "right-header": "500+",
                      "right-footer": "contracts",
                      "left-header": "292",
                      "left-footer": "feeds"
                    }
                  },
                  [
                    _c("CIcon", {
                      staticClass: "my-4",
                      attrs: { name: "cib-linkedin", height: "56" }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "CCol",
              { attrs: { md: "3", sm: "6" } },
              [
                _c(
                  "CWidgetBrand",
                  {
                    attrs: {
                      "right-header": "12",
                      "right-footer": "events",
                      "left-header": "4",
                      "left-footer": "meetings",
                      color: "gradient-warning"
                    }
                  },
                  [
                    _c("CIcon", {
                      staticClass: "my-4",
                      attrs: { name: "cil-calendar", height: "56" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }